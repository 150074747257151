export const ADD_SERVICES = 'ADD_SERVICES';
export const SERVICES_LOADING = 'SERVICES_LOADING';
export const SERVICES_FAILED = 'SERVICES_FAILED';

export const ADD_CLIENTS = 'ADD_CLIENTS';
export const CLIENTS_LOADING = 'CLIENTS_LOADING';
export const CLIENTS_FAILED = 'CLIENTS_FAILED';

export const ADD_TESTIMONIALS = 'ADD_TESTIMONIALS';
export const TESTIMONIALS_LOADING = 'TESTIMONIALS_LOADING';
export const TESTIMONIALS_FAILED = 'TESTIMONIALS_FAILED';

export const ADD_FAQS = 'ADD_FAQS';
export const FAQS_LOADING = 'FAQS_LOADING';
export const FAQS_FAILED = 'FAQS_FAILED';

export const UPDATE_SELECTED_SERVICES = 'UPDATE_SELECTED_SERVICES';